<script setup>
import { ref, reactive } from "vue";

const contactForm = reactive({
  firstName: "",
  lastName: "",
  email: "",
  subject: "Hesaplama Sorunları",
  message: "",
});

const invalidInputForm = ref(false);

const handleSubmit = () => {
  const { firstName, lastName, email, subject, message } = contactForm;

  const mailToLink = `mailto:info@kiratespit.com?subject=${subject}&body=Merhaba, Ben ${firstName} ${lastName}, E-posta adresim ${email}. %0D%0A${message}`;

  window.location.href = mailToLink;
};
</script>

<template>
  <section id="iletisim" class="container py-24 sm:py-32">
    <section class="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div>
        <div class="mb-4">
          <h2 class="text-lg text-primary mb-2 tracking-wider">İletişim</h2>
          <h2 class="text-3xl md:text-4xl font-bold">Bizimle Bağlantıya Geçin</h2>
        </div>
        <p class="mb-8 text-muted-foreground lg:w-5/6">
          Kiralarını değerlendirmek için bize ulaşın. Profesyonel ekibimizle tüm sorularınızı yanıtlamaktan memnuniyet duyarız.
        </p>
        <div class="flex flex-col gap-4">
          <div>
            <div class="flex gap-2 mb-1">
              <div class="font-bold">Bize Mail Gönderin</div>
            </div>
            <div>info@kiratespit.com</div>
          </div>
        </div>
      </div>

      <!-- form -->
      <Card class="bg-muted/60 dark:bg-card">
        <CardHeader class="text-primary text-2xl"></CardHeader>
        <CardContent>
          <form @submit.prevent="handleSubmit" class="grid gap-4">
            <div class="flex flex-col md:flex-row gap-8">
              <div class="flex flex-col w-full gap-1.5">
                <Label for="first-name">Ad</Label>
                <Input id="first-name" type="text" placeholder="Adınızı girin" v-model="contactForm.firstName" />
              </div>
              <div class="flex flex-col w-full gap-1.5">
                <Label for="last-name">Soyad</Label>
                <Input id="last-name" type="text" placeholder="Soyadınızı girin" v-model="contactForm.lastName" />
              </div>
            </div>
            <div class="flex flex-col gap-1.5">
              <Label for="email">E-posta</Label>
              <Input id="email" type="email" placeholder="info@kiratespit.com" v-model="contactForm.email" />
            </div>
            <div class="flex flex-col gap-1.5">
              <Label for="subject">Konu</Label>
              <Select v-model="contactForm.subject">
                <SelectTrigger>
                  <SelectValue placeholder="Bir konu seçin" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="Hesaplama Sorunları">Hesaplama Sorunları</SelectItem>
                    <SelectItem value="Genel Soru">Genel Soru</SelectItem>
                    <SelectItem value="Geri Bildirim">Geri Bildirim</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div class="flex flex-col gap-1.5">
              <Label for="message">Mesaj</Label>
              <Textarea id="message" placeholder="Mesajınızı yazın..." rows="5" v-model="contactForm.message" />
            </div>
            <Alert v-if="invalidInputForm" variant="destructive">
              <Icon name="AlertCircle" class="w-4 h-4" />
              <AlertTitle>Hata</AlertTitle>
              <AlertDescription>Formda bir hata var. Lütfen girdilerinizi kontrol edin.</AlertDescription>
            </Alert>
            <Button class="mt-4" aria-label="Mesaj Gönder">Mesajı Gönder</Button>
          </form>
        </CardContent>
        <CardFooter></CardFooter>
      </Card>
    </section>
  </section>
</template>

