<script setup>
import { useColorMode } from "@vueuse/core";
const mode = useColorMode();
</script>

<template>
  <section class="container">
    <div
      class="grid place-items-center lg:max-w-screen-xl gap-8 mx-auto py-20 md:py-32"
    >
      <div class="text-center space-y-8">
        <Badge
          variant="outline"
          class="text-sm py-2"
        >
          <span class="mr-2 text-primary">
            <Badge>Yeni</Badge>
          </span>
          <span> Özellikler Şimdi Aktif! </span>
        </Badge>

        <div
          class="max-w-screen-md mx-auto text-center text-5xl md:text-6xl font-bold"
        >
          <h1>
            <span
              class="text-transparent bg-gradient-to-r from-[#D247BF] to-primary bg-clip-text"
              >KiraTespit
            </span>
            ile Doğru Kira Değerinizi Belirleyin
          </h1>
        </div>

        <p class="max-w-screen-sm mx-auto text-xl text-muted-foreground">
          Kiranızı güncellemek mi istiyorsunuz? Uzman ekibimizle en doğru kira bedeline hızlı ve kolay bir şekilde ulaşın.
        </p>

        <div class="space-y-4 md:space-y-0 md:space-x-4">
          <Button class="w-5/6 md:w-1/4 font-bold group/arrow">
            Hemen Başlayın
            <Icon name="ArrowRight" class="size-5 ml-2 group-hover/arrow:translate-x-1 transition-transform" />
          </Button>
        </div>
      </div>

      <div class="relative group mt-14">
        <div
          class="absolute -top-6 right-12 w-[90%] h-12 lg:h-[80%] bg-primary/50 blur-3xl rounded-full img-shadow-animation"
        ></div>

        <img
          class="w-full md:w-[1200px] mx-auto rounded-lg relative leading-none flex items-center border border-t-2 border-t-primary/30 img-border-animation"
          :src="
            mode.value == 'light' ? 'hero-image-light.jpg' : 'hero-image-dark.jpg'
          "
          loading="lazy"
          alt="KiraTespit ile Kiranızı Belirleyin"
        />

        <div
          class="absolute bottom-0 left-0 w-full h-20 md:h-28 bg-gradient-to-b from-background/0 via-background/50 to-background rounded-lg"
        ></div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.img-shadow-animation {
  animation-name: img-shadow-animation;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-direction: alternate;
}

.img-border-animation {
  animation-name: img-border-animation;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-direction: alternate;
}

@keyframes img-shadow-animation {
  from {
    opacity: 0.5;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes img-border-animation {
  from {
    border-top-color: rgba(0, 0, 0, 0.1);
  }

  to {
    border-top-color: rgba(0, 0, 0, 0.6);
  }
}
</style>
