<script setup>
const HowItWorksList = [
  {
    badgeTitle: "Değerlendir",
    title: "Emlakınızı Kapsamlı Bir Şekilde Değerlendiriyoruz",
    description:
      "Profesyonel analizlerimizle mülkünüzün değerini ve potansiyelini belirliyoruz.",
    image: "evaluation.webp",
  },
  {
    badgeTitle: "Analiz Et",
    title: "Pazar Trendlerini Derinlemesine İnceliyoruz",
    description:
      "Güncel piyasa verilerini kullanarak kiralık emlak piyasasındaki trendleri analiz ediyoruz.",
    image: "market-analysis.webp",
  },
  {
    badgeTitle: "Fiyatlandır",
    title: "Optimum Kira Fiyatlarını Belirliyoruz",
    description:
      "Elde edilen veriler ışığında mülkünüz için en uygun kira fiyatını belirliyoruz.",
    image: "pricing.webp",
  },
  {
    badgeTitle: "Tavsiye Ver",
    title: "Kişiselleştirilmiş Çözümler Sunuyoruz",
    description:
      "Değerlendirmelerimize dayanarak, emlakınız için en iyi stratejileri öneriyoruz.",
    image: "recommendations.webp",
  },
];
</script>

<template>
  <section
    id="nasil-calisir"
    class="container py-24 sm:py-32"
  >
    <div class="text-center mb-8">
      <h2 class="text-lg text-primary text-center mb-2 tracking-wider">
        Nasıl Çalışır
      </h2>

      <h2 class="text-3xl md:text-4xl text-center font-bold">
        Adım Adım Süreç
      </h2>
    </div>

    <div class="lg:w-[80%] mx-auto relative">
      <div
        v-for="(
          { badgeTitle, title, description, image }, index
        ) in HowItWorksList"
        :key="title"
        :class="[
          'flex mb-8 items-center',
          { ' flex-row-reverse': index % 2 !== 0 },
        ]"
      >
        <Card class="h-full bg-transparent border-0 shadow-none">
          <CardHeader>
            <div class="pb-4">
              <Badge>{{ badgeTitle }}</Badge>
            </div>

            <CardTitle>
              {{ title }}
            </CardTitle>
          </CardHeader>

          <CardContent class="text-muted-foreground w-[80%]">
            {{ description }}
          </CardContent>
        </Card>

        <img
          :src="image"
          :alt="`Image describing ${title} `"
          class="w-[150px] md:w-[250px] lg:w-[300px] mx-auto -scale-x-100 rounded-lg"
          width="300"
          height="300"
          loading="lazy"
        />
        <div
          :class="[
            '-z-10 absolute right-0 w-44 h-72 lg:w-64 lg:h-80 rounded-full bg-primary/15 dark:bg-primary/10 blur-3xl',
            {
              'left-0': index % 2 !== 0,
            },
          ]"
        ></div>
      </div>
    </div>
  </section>
</template>
