<template>
  <keep-alive>
    <component :is="`Icon${name}`" :options="attrs"></component>
  </keep-alive>
</template>

<script setup>
import { useAttrs } from 'vue'
const attrs = useAttrs()
defineProps({ name: { type: String } })
</script>
