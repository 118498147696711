const register = (app) => {
  const requireComponent = import.meta.glob('./*.vue', { eager: true });
  for (const filePath in requireComponent) {
    const componentConfig = requireComponent[filePath]
    const componentName = filePath.split('/').pop()?.replace(/\.\w+$/, '') || ''

    app.component(`Icon${componentName}`, componentConfig.default || componentConfig)
  }

  return app
}

export default { register }
