<template>
  <section
    id="gorusler"
    class="container py-24 sm:py-32"
  >
    <div class="text-center mb-8">
      <h2 class="text-lg text-primary text-center mb-2 tracking-wider">
        Görüşler
      </h2>

      <h2 class="text-3xl md:text-4xl text-center font-bold mb-4">
        1000'den Fazla Müşterimizin Yorumlarını Okuyun
      </h2>
    </div>

    <Carousel
      :opts="{
        align: 'start',
      }"
      class="relative w-[80%] sm:w-[90%] lg:max-w-screen-xl mx-auto"
    >
      <CarouselContent>
        <CarouselItem
          v-for="review in reviewList"
          :key="review.name"
          class="md:basis-1/2 lg:basis-1/3"
        >
          <Card class="bg-muted/50 dark:bg-card">
            <CardContent class="pt-6 pb-0">
              <div class="flex gap-1 pb-6">
                <Icon
                  v-for="n in 5"
                  :key="n"
                  name="Star"
                  class="size-4"
                  :fill="n <= Math.round(review.rating) ? '#f59e0b' : 'none'"
                  :stroke="'#f59e0b'"
                />
              </div>

              "{{ review.comment }}"
            </CardContent>

            <CardHeader>
              <div class="flex flex-row items-center gap-4">
                <Avatar>
                  <AvatarImage
                    :src="review.image"
                    :alt="review.name"
                  />
                  <AvatarFallback>{{ review.name.charAt(0) }}</AvatarFallback>
                </Avatar>

                <div class="flex flex-col">
                  <CardTitle class="text-lg">{{ review.name }}</CardTitle>
                  <CardDescription>{{ review.userName }}</CardDescription>
                </div>
              </div>
            </CardHeader>
          </Card>
        </CarouselItem>
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  </section>
</template>

<script setup>
const reviewList = [
  {
    image: "https://github.com/shadcn.png",
    name: "Ahmet Yılmaz",
    userName: "Gayrimenkul Danışmanı",
    comment:
      "Kira Tespit sayesinde doğru kira bedelini kolayca belirleyebildim. Hizmetlerinden çok memnunum!",
    rating: 5.0,
  },
  {
    image: "https://github.com/shadcn.png",
    name: "Elif Demir",
    userName: "Ev Sahibi",
    comment:
      "Uzman ekibiyle harika bir deneyim yaşadım. Kesinlikle tavsiye ederim.",
    rating: 4.8,
  },
  {
    image: "https://github.com/shadcn.png",
    name: "Mehmet Öz",
    userName: "Kiracı",
    comment:
      "Kira tespiti konusunda aklımdaki tüm sorulara cevap buldum. Teşekkürler!",
    rating: 4.9,
  },
  {
    image: "https://github.com/shadcn.png",
    name: "Ayşe Çelik",
    userName: "Yatırımcı",
    comment:
      "Profesyonel ve hızlı hizmetleri için Kira Tespit'e teşekkür ederim.",
    rating: 5.0,
  },
  {
    image: "https://github.com/shadcn.png",
    name: "Ali Kaya",
    userName: "Mimar",
    comment:
      "Gayrimenkul değerleme konusunda en güvenilir adres. Kesinlikle öneririm.",
    rating: 5.0,
  },
  {
    image: "https://github.com/shadcn.png",
    name: "Zeynep Arslan",
    userName: "İşletme Sahibi",
    comment:
      "Kira Tespit ile çalışmak gerçekten keyifliydi. İhtiyaçlarımı anladılar ve en iyi çözümü sundular.",
    rating: 4.9,
  },
];
</script>
