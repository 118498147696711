<script setup lang="ts">
import { useHead } from '@unhead/vue'

useHead({
  title: 'Kira Tespit'
})



</script>

<template>
  <Navbar />
  <Hero />
  <HowItWorks /> 
  <Testimonials />
  <Contact />
  <!--<FAQ /> -->
  <Footer />  

</template>