<template>
  <svg
    :class="opts.class"
    xmlns="http://www.w3.org/2000/svg"
    :width="opts.width"
    :height="opts.height"
    viewBox="0 0 24 24"
  >
    <path
      :fill="fill"
      :stroke="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="m12 2l3.09 6.26L22 9.27l-5 4.87l1.18 6.88L12 17.77l-6.18 3.25L7 14.14L2 9.27l6.91-1.01z"
    />
  </svg>
</template>

<script setup>
import { useIcon } from "@/composables/useIcon";

const props = defineProps({
  options: { type: Object },
  fill: { type: String, default: "currentColor" },
  stroke: { type: String, default: "currentColor" },
});

const { opts } = useIcon({
  defaults: {
    bg: props.options?.bg || "",
    width: props.options?.width || 16,
    height: props.options?.height || 16,
    class: props.options?.class || "w-5 h-5",
  },
});
</script>
