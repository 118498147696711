<script setup>
import { ref } from "vue";
import { useColorMode } from "@vueuse/core";
const mode = useColorMode();

const routeList = [
  {
    href: "#nasil-calisir",
    label: "Nasıl Çalışır",
  },
  {
    href: "#gorusler",
    label: "Görüşler",
  },
  // {
  //   href: "#team",
  //   label: "Ekip",
  // },
  {
    href: "#iletisim",
    label: "İletişim",
  },
  // {
  //   href: "#faq",
  //   label: "SSS",
  // },
];

const featureList = [
  {
    title: "Değerinizi Gösterin",
    description: "Ürününüzün kullanıcı sorunlarını nasıl çözdüğünü vurgulayın.",
  },
  {
    title: "Güven Oluşturun",
    description:
      "Güven ve itibar oluşturmak için sosyal kanıt unsurlarını kullanın.",
  },
  {
    title: "Potansiyel Müşterileri Yakalayın",
    description:
      "Potansiyel müşteri formunuzu görsel olarak çekici ve stratejik hale getirin.",
  },
];
const isOpen = ref(false);
</script>

<template>
  <header :class="{'shadow-light': mode=== 'light',
  'shadow-dark': mode === 'dark',
  'w-[90%] md:w-[70%] lg:w-[75%] lg:max-w-screen-xl top-5 mx-auto sticky border z-40 rounded-2xl flex justify-between items-center p-2 bg-card shadow-md': true,
  }">
    <a href="/" class="font-bold text-lg flex items-center">
      <Icon name="Logo" class="bg-gradient-to-tr from-primary via-primary/70 to-primary rounded-lg w-28 h-14 mr-2 px-4 border text-white" />
    </a>

    <!--Mobile-->
    <div class="flex items-center lg:hidden">
      <Sheet v-model:open="isOpen">
        <SheetTrigger as-child>
          <Icon name="Menu" class="cursor-pointer" @click="isOpen = true" />
        </SheetTrigger>

        <SheetContent
          side="left"
          class="flex flex-col justify-between rounded-tr-2xl rounded-br-2xl bg-card"
        >
          <div>
            <SheetHeader class="mb-4 ml-4">
              <SheetTitle class="flex items-center">
                <a
                  href="/"
                  class="flex items-center"
                >
                  <Icon name="ChevronsDown" class="bg-gradient-to-tr from-primary/70 via-primary to-primary/70 rounded-lg size-9 mr-2 border text-white" />
                  KiraTespit
                </a>
              </SheetTitle>
            </SheetHeader>

            <div class="flex flex-col gap-2">
              <Button
                v-for="{ href, label } in routeList"
                :key="label"
                as-child
                variant="ghost"
                class="justify-start text-base"
              >
                <a
                  @click="isOpen = false"
                  :href="href"
                >
                  {{ label }}
                </a>
              </Button>
            </div>
          </div>

          <SheetFooter class="flex-col sm:flex-col justify-start items-start">
            <Separator class="mb-2" />

            <ToggleTheme />
          </SheetFooter>
        </SheetContent>
      </Sheet>
    </div>

    <!-- Desktop -->
    <NavigationMenu class="hidden lg:block">
      <NavigationMenuList>
        <!-- <NavigationMenuItem>
          <NavigationMenuTrigger class="bg-card text-base">
            Features
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <div class="grid w-[600px] grid-cols-2 gap-5 p-4">
              <img
                src="https://www.radix-vue.com/logo.svg"
                alt="Beach"
                class="h-full w-full rounded-md object-cover"
              />
              <ul class="flex flex-col gap-2">
                <li
                  v-for="{ title, description } in featureList"
                  :key="title"
                  class="rounded-md p-3 text-sm hover:bg-muted"
                >
                  <p class="mb-1 font-semibold leading-none text-foreground">
                    {{ title }}
                  </p>
                  <p class="line-clamp-2 text-muted-foreground">
                    {{ description }}
                  </p>
                </li>
              </ul>
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem> -->

        <NavigationMenuItem>
          <NavigationMenuLink asChild>
            <Button
              v-for="{ href, label } in routeList"
              :key="label"
              as-child
              variant="ghost"
              class="justify-start text-base"
            >
              <a :href="href">
                {{ label }}
              </a>
            </Button>
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>

    <div class="hidden lg:flex">
      <ToggleTheme />
    </div>    
  </header>
</template>


<style scoped>
.shadow-light {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.085);
}

.shadow-dark {
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.141);
}
</style>