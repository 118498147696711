<template>
  <svg
    :class="opts.class"
    xmlns="http://www.w3.org/2000/svg"
    :width="opts.width"
    :height="opts.height"
    viewBox="0 0 24 24"
    aria-hidden="true"
  >
    <g
      fill="currentColor"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    >
      <circle cx="12" cy="12" r="4" />
      <path
        d="M12 2v2m0 16v2M4.93 4.93l1.41 1.41m11.32 11.32l1.41 1.41M2 12h2m16 0h2M6.34 17.66l-1.41 1.41M19.07 4.93l-1.41 1.41"
      />
    </g>
  </svg>
</template>

<script setup>
import { useIcon } from '@/composables/useIcon'

const props = defineProps({
  options: { type: Object }
})

const { opts } = useIcon({
  defaults: {
    bg: props.options.bg || '',
    width: props.options.width || 16,
    height: props.options.height || 16,
    class: props.options.class || 'w-5 h-5'
  }
})
</script>

<style lang="scss" scoped></style>
