<template>
  <Button
    @click="toggleMode"
    size="sm"
    variant="ghost"
    class="w-full justify-start"
  >
    <div v-if="mode === 'light'" class="flex gap-2">
      <Icon name="Moon" class="size-5" />
      <span class="block lg:hidden">Dark</span>
    </div>

    <div v-else class="flex gap-2">
      <Icon name="Sun" class="size-5" />
      <span class="block lg:hidden">Light</span>
    </div>

    <span class="sr-only">Toggle theme</span>
  </Button>
</template>

<script setup>
import { useColorMode } from "@vueuse/core";
import { Button } from "@/components/ui/button";

const mode = useColorMode();

function toggleMode() {
  mode.value = mode.value === 'dark' ? 'light' : 'dark';
}
</script>
