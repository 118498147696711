import { computed, reactive } from 'vue'

export function useIcon(options = {}, props) {
  const defaultOptions = reactive(options.defaults || {})

  const opts = computed(() => {
    return {
      ...defaultOptions,
      ...props
    }
  })

  return {
    defaults: defaultOptions,
    opts
  }
}

export default useIcon
