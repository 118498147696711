function reduceComponents (acc, [key, comp]) {
  acc[key.replace(/\.\/_?(.*?)\.vue/g, '$1')] = comp
  return acc
}

export function getComponents () {
  return Object.entries(import.meta.glob('./**/[^_]*.vue', { eager: true }))
    .reduce(reduceComponents, {})
}

export function getAsyncComponents () {
  return Object.entries(import.meta.glob('./**/_*.vue', { eager: true }))
    .reduce(reduceComponents, {})
}

export function getAllComponents () {
  return Object.entries(import.meta.glob('./**/*.vue', { eager: true }))
    .reduce(reduceComponents, {})
}

export function register (app) {
  const requireComponent = getComponents()
  for (const filePath in requireComponent) {
    const componentConfig = requireComponent[filePath]
    const componentName = filePath.split('/').pop()?.replace(/\.\w+$/, '') || ''

    app.component(componentName, componentConfig.default || componentConfig)
  }

  return app
}

export default { register }
