import "./assets/index.css";

import { createApp } from "vue";
import { createPinia } from "pinia";
import { createHead } from "@unhead/vue";

import App from "./App.vue";
import router from "./router";
import * as Sentry from "@sentry/vue";
import i18n from "@/locales/i18n";
import icons from "@/components/icons";
import components from "@/components/index";

const app = createApp(App);
const head = createHead();

Sentry.init({
  app,
  dsn: "https://e79091d411a508d3ef83083d891e6d02@o4505374616387584.ingest.us.sentry.io/4507762943721472",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/, 'https://www.kiratespit.com'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
});


app.use(head);
app.use(createPinia());
app.use(router);
app.use(i18n);
icons.register(app);
components.register(app);

app.mount("#app");
