<template>
  <svg
    :class="opts.class"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    :width="opts.width"
    :height="opts.height"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M5 12h14m-7-7l7 7l-7 7"
    />
  </svg>
</template>

<script setup>
import { useIcon } from "@/composables/useIcon";

const props = defineProps({
  options: { type: Object },
});

const { opts } = useIcon({
  defaults: {
    bg: props.options.bg || "",
    width: props.options.width || 16,
    height: props.options.height || 16,
    class: props.options.class || "w-5 h-5",
  },
});
</script>

<style lang="scss" scoped></style>
