import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    welcome: 'Welcome',
    homeDescription: 'This is the home page of our multilingual site.',
    about: 'About',
    aboutDescription: 'Learn more about us on this page.',
    backHome: 'Back to Home',
    chooseLanguage: 'Choose Language:'
  },
  tr: {
    welcome: 'Hoşgeldiniz',
    homeDescription: 'Bu, çok dilli sitemizin ana sayfasıdır.',
    about: 'Hakkımızda',
    aboutDescription: 'Bu sayfada bizim hakkımızda daha fazla bilgi edinin.',
    backHome: 'Ana Sayfaya Dön',
    chooseLanguage: 'Dil Seçin:'
  }
}

const i18nOptions = {
  locale: 'en',
  fallbackLocale: 'en',
  messages
}

const i18n = createI18n(i18nOptions)

export default i18n
